import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef, effect } from '@angular/core';

import { UserPermissionsStore } from '@sulser-print/services/user-permissions-store';

export type ViewPermission = (() => boolean) | boolean | string;

@Directive({
	selector: '[viewPermission]',
	standalone: true,
})
export class ViewPermissionDirective {
	private _permission?: ViewPermission;

	constructor(
		private readonly viewContainerReference: ViewContainerRef,
		private readonly templateReference: TemplateRef<any>,
		private readonly userPermissionsStore: UserPermissionsStore,
		private readonly changeDetectorReference: ChangeDetectorRef,
	) {
		effect(() => {
			// This is required to update the view when the permissions change.
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const permissions = this.userPermissionsStore.permissions();
			this.updateView();
		});
	}

	private hasAccess(): boolean {
		switch (typeof this._permission) {
			case 'function': {
				return this._permission();
			}
			case 'string': {
				return this.userPermissionsStore.hasPermission(this._permission);
			}
			case 'boolean': {
				return this._permission;
			}
			default: {
				return true;
			}
		}
	}

	/**
	 * Update view.
	 */
	private updateView() {
		this.viewContainerReference.clear();

		if (this.hasAccess()) {
			this.viewContainerReference.createEmbeddedView(this.templateReference);
			this.changeDetectorReference.markForCheck();
		}
	}

	@Input()
	set viewPermission(permission: ViewPermission) {
		this._permission = permission;
	}
}
